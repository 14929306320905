import React from 'react';
import classNames from 'classnames';

import ProLink from '../../../../shared/ProLink/ProLink';

const renderMobileNavLinks = (links, activeLink, setActiveLinkEvent, customEventHandler) => {
    return links.map((link, i) => {
        const { linkText, linkTarget } = link;
        return (
            <li key={i} className="smort-center">
                <ProLink
                    to={linkTarget}
                    className={classNames('link-padding col-lg-12', { 'active-link': activeLink === linkText })}
                    onClick={() => {
                        setActiveLinkEvent(linkText);
                        customEventHandler({ name: 'tab_click', details: { title: linkText } });
                    }}
                >
                    {linkText}
                    <i className="ngp-icon icon-r-arrows" aria-hidden="true" />
                </ProLink>
            </li>
        );
    });
};

export default renderMobileNavLinks;
