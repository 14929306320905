import React from 'react';

const renderTitles = titles => {
    return titles.map((title, i) => {
        const { titleText, titleTarget } = title;
        return (
            <a key={i} href={titleTarget} className="h3 header-light">
                {titleText}
            </a>
        );
    });
};

export default renderTitles