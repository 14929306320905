import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Sticky from 'react-stickynode';
import MediaQuery from 'react-responsive';

import { renderNavLinks, renderTitles, renderMobileNavLinks } from './helpers';
import { isClient, dataLayer } from '../../../helpers';
import './NavigationHub.scss';

const NavigationHub = ({ data = {}, location }) => {
    const [showExpandedNav, setShowExpandedNav] = useState(false);
    const [activeLink, setActiveLink] = useState(null);

    const toggleExpandedNav = e => {
        e.preventDefault();
        setShowExpandedNav(!showExpandedNav);
    };

    const customEventHandler = ({ name, details }) => {
        dataLayer.triggerEvent(name, details);
        return true;
    };

    useEffect(() => {
        setActiveLink(getActiveLink());
    }, [location.pathname]);

    const getActiveLink = () => {
        const { navLinks = [] } = data;
        const lastPath = location.pathname
            .split('/')
            .pop()
            .replace(/-/g, '');
        const activeLink = navLinks.find(navLink => {
            const { linkTarget } = navLink;
            const linkTargetLastPath = linkTarget
                .split('/')
                .pop()
                .replace(/-/g, '');
            return linkTargetLastPath === lastPath;
        });
        return activeLink ? activeLink.linkText : null;
    };

    const setActiveLinkEvent = linkText => {
        setActiveLink(linkText);
    };

    const { navTitles = [], navLinks = [], navButton = {}, dark } = data;
    const { link, title } = navButton;

    const wrapperClasses = classNames({
        'container-fluid': true,
        solutions: true,
        'solutions-light': !dark,
        'solutions-dark': dark,
    });

    const mobileWrapperClasses = classNames({
        'container-fluid': true,
        'solutions-mobile': true,
        'solutions-mobile-dark': dark,
    });

    return (
        <Sticky enabled={true} innerZ={99}>
            <MediaQuery query="(min-width: 992px)">
                {matches =>
                    !matches && isClient() ? (
                        <div className={mobileWrapperClasses}>
                            <div className="container">
                                <div className="row smort-center tw-flex-nowrap">
                                    <div className="col-1 text-left">
                                        <button
                                            className="btn-no-style h3"
                                            onClick={toggleExpandedNav}
                                            aria-label={
                                                showExpandedNav ? 'toggle navigation closed' : 'toggle navigation open'
                                            }
                                        >
                                            <i
                                                className={classNames({
                                                    fa: true,
                                                    'fa-bars': true,
                                                    'fa-rotate-90': showExpandedNav,
                                                })}
                                            />
                                        </button>
                                    </div>
                                    <div className="col-7 mobile-title">{renderTitles(navTitles)}</div>
                                    <div className="col-4 text-right">
                                        <a
                                            href={link}
                                            className={classNames({
                                                btn: true,
                                                'btn-solutions-light': !dark,
                                                'btn-solutions-dark': dark,
                                            })}
                                            onClick={() =>
                                                customEventHandler({ name: 'tab_click', details: { title: title } })
                                            }
                                        >
                                            {title}
                                        </a>
                                    </div>
                                </div>
                                <div className={classNames({ row: true, collapse: !showExpandedNav })}>
                                    <div className="container list-items">
                                        <ul>
                                            {renderMobileNavLinks(
                                                navLinks,
                                                activeLink,
                                                setActiveLinkEvent,
                                                customEventHandler
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className={wrapperClasses}>
                            <div className="container">
                                <div className="row smort-center">
                                    <div className="col-md-4">{renderTitles(navTitles)}</div>
                                    <div className="col-md-6 text-right h6">
                                        {renderNavLinks(navLinks, activeLink, setActiveLink, customEventHandler)}
                                    </div>
                                    <div className="col-md-2 text-right">
                                        <a
                                            href={link}
                                            className={classNames({
                                                btn: true,
                                                'btn-solutions-light': !dark,
                                                'btn-solutions-dark': dark,
                                            })}
                                            onClick={() =>
                                                customEventHandler({ name: 'tab_click', details: { title: title } })
                                            }
                                        >
                                            {title}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </MediaQuery>
        </Sticky>
    );
};

export default NavigationHub;
